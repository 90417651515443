import * as React from 'react';
import IndexLayout from '../../layouts';

import Footer from '../../components/Footer/Footer';
import SEO from '../../components/SEO/SEO';

import InscriptionHero from '../../components/Inscription/InscriptionHero/InscriptionHero';
import { LocaleTypes } from '../../data/locales';
import InscriptionAcquisition from '../../components/Inscription/InscriptionAquirence/InscriptionAcquisition';
import { FormattedMessage } from 'react-intl';

interface InscriptionAcquisitionPageProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const acquisition: React.FC<InscriptionAcquisitionPageProps> = ({pathContext, location}) => {

  const content = () => (
    <>
      <SEO titleId="inscription.acquisition.title" descriptionId="inscription.acquisition.description" />
      <InscriptionHero>
        <h1>
          <FormattedMessage id="inscription.acquisition.title" />
        </h1>
        <p>
        <FormattedMessage id="inscription.acquisition.description" />
        </p>
      </InscriptionHero>

      <InscriptionAcquisition/>

      <Footer theme="primary" />
    </>
  )

  return (
    <IndexLayout
      render={ content }
      locale={pathContext.localeCode}
      navbarTheme="light"
      location={location}
    />
  )
}

export default acquisition